import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { UserViewInformation } from './UserInfoPage'
import { useIntl } from 'react-intl'

const BasicUserInfo: React.FC<{
  loading: boolean
  userViewInfo?: UserViewInformation
}> = ({ loading, userViewInfo }) => {
  const intl = useIntl()

  if (loading) return <CircularProgress />

  return (
    <>
      <p>
        <strong>{intl.formatMessage({ id: 'user-name-DPDSA' })}</strong>
      </p>
      {userViewInfo?.displayName ? (
        <p>{userViewInfo.displayName}</p>
      ) : (
        <p>
          <i>{intl.formatMessage({ id: 'name-not-found' })}</i>
        </p>
      )}
    </>
  )
}

export default BasicUserInfo
