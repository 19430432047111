import { constants as c } from '../constants'

export type APIEvent = {
  name: string
  id?: string
  short_description: string
  deleted?: boolean
  description: string
  description_html: string | null
  info_url: string
  provider: string
  event_status?: ToUnion<typeof c.EVENT_STATUS>
  publication_status: ToUnion<typeof c.PUBLICATION_STATUS>
  super_event_type: SuperEventType | null
  super_event: WrappedId | null
  publisher: string
  location: WrappedId | null
  location_extra_info: string | null
  images: Image[]
  offers: Offer[]
  keywords: WrappedId[]
  // libraryKeyword is optional for backward compatibility
  // Once this feature is on production, optionality should be removed
  libraryKeywords?: WrappedId[]
  audience: WrappedId[]
  in_language: WrappedId[]
  event_registration_link: string | null
  date_published: string | null
  start_time: string | null
  end_time: string | null
  audience_min_age: number | null
  audience_max_age: number | null
  hobby_categories: WrappedId[]
  kaikukortti?: boolean
  museokortti?: boolean

  /**
   * @deprecated TODO: Courses extension is removed from frontend.
   * Remove this, once courses is removed from backend (service).
   */
  extension_course: CourseInfo
  // When sending data we should never send the sub_events data but it always exists in response
  sub_events?: APIEvent[]
  external_links: ExternalLink[]
}

type ToUnion<T> = T[keyof T]

export type UIEvent = {
  id?: string
  name: string
  short_description: string
  description: string
  description_html?: string
  info_url: string
  provider: string
  event_status?: ToUnion<typeof c.EVENT_STATUS>
  publication_status: ToUnion<typeof c.PUBLICATION_STATUS>
  super_event_type: SuperEventType | null
  super_event?: WrappedId
  organization: string
  location?: WrappedId
  location_extra_info?: string
  image?: Image
  offers: Offer[]
  keywords: string[]
  metaKeywords: string[]
  // libraryKeyword is optional for backward compatibility
  // Once this feature is on production, optionality should be removed
  libraryKeywords?: string[]
  placeKeywords: string[]
  audience: string[]
  in_language: string[]
  event_registration_link?: string
  date_published?: string
  start_time?: string
  end_time?: string
  audience_min_age?: string
  audience_max_age?: string
  hobbyCategories: HobbyCategory[]
  enrolment_start_time?: string
  enrolment_end_time?: string
  minimum_attendee_capacity?: string
  maximum_attendee_capacity?: string
  // TODO: Look into changing this to array
  sub_events?: { [x: number]: APIEvent }
  extlink_facebook?: string
  extlink_twitter?: string
  extlink_instagram?: string
  deleted?: boolean
  kaikukortti?: boolean
  museokortti?: boolean
}

export type HobbyCategory = {
  id: string
  name: string
  created_time: string
  last_modified_time: string
  name_fi: string
  name_sv: string
  name_en: string
  name_zh_hans?: string
  name_ru?: string
  name_ar?: string
  data_source: string
  topics: string[]
} & EventsMetaFields

/**
 * @deprecated TODO: Courses extension is removed from frontend.
 * Remove this, once courses is removed from backend (service).
 */
export type CourseInfo = {
  enrolment_start_time: string | null
  enrolment_end_time: string | null
  minimum_attendee_capacity: number | null
  maximum_attendee_capacity: number | null
}

export type ExternalLink = {
  name: 'extlink_facebook' | 'extlink_twitter' | 'extlink_instagram'
  link: string
  language: string
}

export type WrappedId = {
  '@id': string
}

enum SuperEventType {
  SUPER_EVENT_TYPE_RECURRING = 'recurring',
  SUPER_EVENT_TYPE_UMBRELLA = 'umbrella',
}

type Image = {
  id: number
  license: string
  created_time: string
  last_modified_time: string
  name: string
  url: string
  cropping: string
  photographer_name: string
  alt_text: string
  data_source: string
  publisher: string
} & EventsMetaFields

type Offer = {
  is_free: boolean
  price?: string
  description?: string
  info_url?: string
}

export type EventTime = {
  start_time?: string
  end_time?: string
}

export type Keyword = {
  id: string
  alt_labels: string[]
  created_time: string
  last_modified_time: string
  aggregate: boolean
  deprecated: boolean
  has_upcoming_events: boolean
  n_events: number
  image: Image | null
  data_source: string
  publisher: string
  replaced_by: string
  name: MultiLanguageField
} & EventsMetaFields

export type KeywordSet = {
  created_time: string
  data_source: string
  id: string
  image: Image | null
  keywords: Keyword[]
  last_modified_time: string
  name: MultiLanguageField
  organization: null // TODO: Remove the property from backend.
  usage: string
}

type EventsMetaFields = {
  '@id': string
  '@context': string
  '@type': string
}

export type Language = 'fi' | 'sv' | 'en'
export type MultiLanguageField = {
  fi: string | null
  sv: string | null
  en: string | null
}

export type RawFormValues = {}

export type PaginatedResponse<T> = {
  data: T[]
  meta: {
    /** The total number of results across all pages. */
    count: number

    /**  URL to the next page of results. If null, this is the last page. */
    next: string | null

    /** URL to the previous page of results. If null, this is the first page. */
    previous: string | null
  }
}

export type User = {
  last_login: string | null
  username: string
  email: string
  date_joined: string
  first_name: string
  last_name: string
  uuid: string
  department_name: string | null
  is_staff: boolean
  display_name: string
  organization: string
  admin_organizations: string[]
  organization_memberships: string[]
  superuser: boolean
}

export type Organization = {
  id: string
  data_source: string
  classification: string | null
  name: string
  founding_date: string | null
  dissolution_date: string | null
  parent_organization: string | null
  sub_organizations: Organization[]
  affiliated_organizations: Organization[]
  created_time: string
  last_modified_time: string
  is_affiliated: boolean
  replaced_by: Organization | null
  has_regular_users: boolean
} & EventsMetaFields

export type OrganizationAccessRequest = {
  created_time: string
  data_source: string
  email: string
  granted_by: number | null
  granted_date: string | null
  id: string
  last_modified_time: string
  name: string
  organization: string
  request_status: number
  request_date: string
  requester: User
  rejected_by: number | null
} & EventsMetaFields

const isOrganizationAccessRequest = (
  obj: unknown
): obj is OrganizationAccessRequest => {
  if (obj === null || typeof obj !== 'object') return false

  const OARSchema: Omit<OrganizationAccessRequest, 'requester'> = {
    '@context': 'string',
    '@id': 'string',
    '@type': 'string',
    created_time: 'string',
    data_source: 'string',
    email: 'string',
    granted_by: null,
    granted_date: null,
    id: 'string',
    last_modified_time: 'string',
    name: 'string',
    organization: 'string',
    request_status: 0,
    request_date: 'string',
    rejected_by: null,
  }

  const missingProperties = Object.keys(OARSchema).filter(
    (key) => !(key in obj)
  )

  return missingProperties.length === 0
}

export const mapResponseDataToOAR = (
  obj: unknown
): OrganizationAccessRequest | undefined => {
  if (!isOrganizationAccessRequest(obj)) return undefined

  return {
    '@context': obj['@context'],
    '@id': obj['@id'],
    '@type': obj['@type'],
    created_time: obj.created_time,
    data_source: obj.data_source,
    email: obj.email,
    granted_by: obj.granted_by,
    granted_date: obj.granted_date,
    id: obj.id,
    last_modified_time: obj.last_modified_time,
    name: obj.name,
    organization: obj.organization,
    rejected_by: obj.rejected_by,
    request_date: obj.request_date,
    request_status: obj.request_status,
    requester: obj.requester,
  }
}
