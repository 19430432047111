import './Editor.scss'

import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { push } from 'connected-react-router'
import moment from 'moment'

import {
  executeSendRequest as executeSendRequestAction,
  clearData as clearDataAction,
  setEditorAuthFlashMsg as setEditorAuthFlashMsgAction,
  setLanguages as setLanguageAction,
  setEventForEditing as setEventForEditingAction,
  setValidationErrors as setValidationErrorsAction,
} from '../../actions/editor'
import { editorModeSet } from '../../store/editor'
import {
  clearFlashMsg as clearFlashMsgAction,
  setFlashMsg as setFlashMsgAction,
} from '../../store/app/index'
import { FormFields } from '../../components/FormFields/FormFields'
import { EventQueryParams, fetchEvent } from '../../utils/events'
import {
  getOrganizationAncestors,
  getOrganizationMembershipIds,
} from '../../utils/user'
import { EditorHeader } from './EditorHeader'
import { EditorButtons } from './EditorButtons'

// sentinel for authentication alert
let sentinel = true

export class EditorPage extends React.Component {
  state = {
    event: {},
    superEvent: {},
    subEvents: [],
    loading: false,
    isRegularUser: false,
  }

  componentDidMount() {
    const { user, match, setFlashMsg, setEditorAuthFlashMsg } = this.props

    setEditorAuthFlashMsg()

    const params = match.params
    const userHasOrganizations = getOrganizationMembershipIds(user) !== null

    this.setEditorMode({ mode: params.action })

    if (user && !userHasOrganizations) {
      setFlashMsg('user-no-rights-create', 'error', { sticky: true })
    }
    if (params?.action === 'update' && params?.eventId) {
      this.fetchEventData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { event } = this.state

    const publisherId = event.publisher
    const oldPublisherId = prevState.event?.publisher
    const prevParams = prevProps.match?.params || {}
    const currParams = this.props.match?.params || {}

    // check if the editing mode or if the eventId params changed
    if (
      prevParams.action !== currParams.action ||
      prevParams.eventId !== currParams.eventId
    ) {
      if (currParams.action === 'update') {
        this.fetchEventData()
      } else {
        this.clearEventData()
        this.setState({
          event: {},
          superEvent: {},
          subEvents: [],
        })
      }
    }

    if (publisherId && publisherId !== oldPublisherId) {
      getOrganizationAncestors(publisherId).then((response) =>
        this.setState((state) => ({
          ...state,
          event: {
            ...state.event,
            publisherAncestors: response.data.data,
          },
        }))
      )
    }
  }

  componentWillUnmount() {
    this.props.clearFlashMsg()
    if (this.props.match?.params?.action === 'update') {
      this.clearEventData()
    }
  }

  /**
   * Fetches the event, sub event and super event data for the event that is being updated
   */
  fetchEventData = async () => {
    const { setEventForEditing } = this.props
    const eventId = this.props.match?.params?.eventId

    if (!eventId) {
      return
    }

    this.setState({ loading: true })

    const queryParams = new EventQueryParams()
    queryParams.include =
      'keywords,location,audience,in_language,external_links,image,sub_events,hobby_categories'
    queryParams.nocache = moment().unix()

    try {
      const eventData = await fetchEvent(eventId, queryParams, true)
      const [event, subEvents, superEvent] = eventData

      this.setState({ event, subEvents, superEvent })
      setEventForEditing(event)
    } finally {
      this.setState({ loading: false })
    }
  }

  /**
   * Clears the editor data and the event, super event and sub events from the store
   */
  clearEventData = () => {
    const { clearData } = this.props
    clearData()
  }

  setEditorMode = (editorMode) => {
    const { setMode } = this.props
    setMode(editorMode)
  }

  render() {
    const {
      editor,
      user,
      match,
      intl,
      userLocale, // TODO: With hooks, this is redundant
    } = this.props
    const { event, superEvent, loading, subEvents } = this.state
    const editMode = match.params?.action

    // TODO: fix flow for non-authorized users
    if (user && !user.organization && sentinel) {
      setTimeout(
        () => alert(intl.formatMessage({ id: 'editor-sentinel-alert' })),
        1000
      )
      sentinel = false
    }

    return (
      <div className="editor-page">
        <EditorHeader event={event} />

        <div className="container">
          <FormFields
            action={editMode}
            editor={editor}
            event={event}
            superEvent={superEvent}
            user={user}
            loading={loading}
            userLocale={userLocale}
          />
        </div>

        <div className="editor-action-buttons">
          {loading ? (
            <CircularProgress className="loading-spinner" size={50} />
          ) : (
            <EditorButtons
              event={event}
              subEvents={subEvents}
              loading={loading}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  editor: state.editor,
  user: state.user,
  userLocale: state.userLocale,
})

const mapDispatchToProps = (dispatch) => ({
  setEventForEditing: (eventId, user) =>
    dispatch(setEventForEditingAction(eventId, user)),
  setMode: (editorMode) => dispatch(editorModeSet(editorMode)),
  clearData: () => dispatch(clearDataAction()),
  setFlashMsg: (id, status, data) =>
    dispatch(setFlashMsgAction({ msg: id, style: status, data })),
  setEditorAuthFlashMsg: () => dispatch(setEditorAuthFlashMsgAction()),
  setLanguages: (languages) => dispatch(setLanguageAction(languages)),
  setValidationErrors: (errors) => dispatch(setValidationErrorsAction(errors)),
  clearFlashMsg: () => dispatch(clearFlashMsgAction()),
  executeSendRequest: (
    formValues,
    updateExisting,
    publicationStatus,
    subEvents
  ) =>
    dispatch(
      executeSendRequestAction(
        formValues,
        updateExisting,
        publicationStatus,
        subEvents
      )
    ),
  routerPush: (url) => dispatch(push(url)),
})

EditorPage.propTypes = {
  match: PropTypes.object,
  intl: PropTypes.object,
  editor: PropTypes.object,
  user: PropTypes.object,
  setEventForEditing: PropTypes.func,
  clearData: PropTypes.func,
  setFlashMsg: PropTypes.func,
  setEditorAuthFlashMsg: PropTypes.func,
  setLanguages: PropTypes.func,
  setValidationErrors: PropTypes.func,
  clearFlashMsg: PropTypes.func,
  executeSendRequest: PropTypes.func,
  routerPush: PropTypes.func,
  event: PropTypes.object,
  superEvent: PropTypes.object,
  // TODO: Once you upgrade this (subEvents) to typescript, this is typeof array, not object.
  // Now it is object in order to make unit tests pass.
  subEvents: PropTypes.object,
  loading: PropTypes.bool,
  isRegularUser: PropTypes.bool,
  userLocale: PropTypes.object,
}

export const ConnectedEditorPage = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(EditorPage)
)
