import { get, isNull, isUndefined } from 'lodash'

import { constants } from '../constants'
import { doValidations } from '../validation/validator'
import { getContentLanguages } from './language'
import { mapAPIDataToUIFormat } from './formDataMapping'
import { getOrganizationMembershipIds } from './user'
import { eventIsEditable } from './eventIsEditable'

const { PUBLICATION_STATUS, USER_TYPE, SUPER_EVENT_TYPE_UMBRELLA } = constants

const userMayEditCheck = (user, event) => {
  const adminOrganizations = get(user, 'adminOrganizations')
  const userOrganization = get(user, 'organization')
  const eventOrganization = get(event, 'publisher')
  const eventOrganizationAncestors = get(event, 'publisherAncestors')
  const organizationMemberships = get(user, 'organizationMemberships')
  const publicationStatus = get(event, 'publication_status')
  const userHasOrganizations = !isNull(getOrganizationMembershipIds(user))

  let userMayEditVar = false
  if (user?.superuser) return true

  // users that don't belong to any organization are not allowed to edit
  if (!userHasOrganizations) {
    return false
  }
  // If present, also check event organization ancestors for admin orgs.
  // Check admin_organizations and organization_membership, but fall back to user.organization if needed
  if (eventOrganization && eventOrganizationAncestors && adminOrganizations) {
    userMayEditVar =
      adminOrganizations.includes(eventOrganization) ||
      adminOrganizations.some((id) =>
        eventOrganizationAncestors.map((org) => org.id).includes(id)
      )
  } else if (eventOrganization && adminOrganizations) {
    userMayEditVar = adminOrganizations.includes(eventOrganization)
  } else {
    userMayEditVar = eventOrganization === userOrganization
  }

  // exceptions to the above:
  if (eventOrganization && organizationMemberships && !userMayEditVar) {
    // non-admins may still edit drafts if they are organization members
    userMayEditVar =
      organizationMemberships.includes(eventOrganization) &&
      publicationStatus === PUBLICATION_STATUS.DRAFT
  }
  if ((userOrganization || adminOrganizations) && !eventOrganization) {
    // if event has no organization, we are creating a new event. it is allowed for users with organizations,
    // disallowed for everybody else. event organization is set by the API when POSTing.
    userMayEditVar = true
  }

  return userMayEditVar
}

const userCanDoActionCheck = (user, event, action, editor) => {
  const isUmbrellaEvent =
    get(event, 'super_event_type') === SUPER_EVENT_TYPE_UMBRELLA
  const isDraft = get(event, 'publication_status') === PUBLICATION_STATUS.DRAFT
  const isPublic =
    get(event, 'publication_status') === PUBLICATION_STATUS.PUBLIC
  const isRegularUser = get(user, 'userType') === USER_TYPE.REGULAR
  const isSubEvent = !isUndefined(get(event, ['super_event', '@id']))
  const { keywordSets } = editor

  if (action === 'publish') {
    if (!event.id || (isDraft && isSubEvent)) {
      return false
    }
    // format event, so that we can validate it
    const formattedEvent = mapAPIDataToUIFormat(event, keywordSets)
    // validate
    const validations = doValidations(
      formattedEvent,
      getContentLanguages(formattedEvent),
      PUBLICATION_STATUS.PUBLIC,
      keywordSets
    )
    // check if there are errors
    const hasValidationErrors = Object.keys(validations).length > 0

    return !hasValidationErrors
  }
  if (action === 'update' && isDraft && isSubEvent && !isRegularUser) {
    return false
  }
  if (action === 'cancel') {
    return !(isDraft || (isRegularUser && isPublic))
  }
  if (action === 'edit' || action === 'update' || action === 'delete') {
    return !(isRegularUser && (isUmbrellaEvent || isPublic))
  }

  return true
}

export const checkEventEditability = (user, event, action, editor) => {
  const eventIsEditable2 = eventIsEditable(event)
  if (!eventIsEditable2.editable) {
    return eventIsEditable2
  }

  const userMayEdit = userMayEditCheck(user, event)
  const userCanDoAction = userCanDoActionCheck(user, event, action, editor)
  const isDraft = get(event, 'publication_status') === PUBLICATION_STATUS.DRAFT
  const isSubEvent = !isUndefined(get(event, ['super_event', '@id']))

  const getExplanationId = () => {
    if (isDraft && action === 'cancel') {
      return 'draft-cancel'
    }
    if (
      !userCanDoAction &&
      (action === 'publish' || action === 'update') &&
      isSubEvent
    ) {
      return 'draft-publish-subevent'
    }
    if (!userCanDoAction && action === 'publish') {
      return 'event-validation-errors'
    }
    if (!userMayEdit || !userCanDoAction) {
      return 'user-no-rights-edit'
    }

    return undefined
  }

  const explanationId = getExplanationId()
  const editable = userMayEdit && userCanDoAction

  return { editable, explanationId }
}
