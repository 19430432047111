import React from 'react'
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core'

interface UmbrellaCheckboxProps {
  children: React.ReactNode
  name: string
  checked: boolean
  disabled: boolean
  disabledTooltipTitle: string
  handleCheck: () => {}
}

export const UmbrellaCheckbox = ({
  children,
  name,
  checked,
  disabled,
  disabledTooltipTitle,
  handleCheck,
}: UmbrellaCheckboxProps): React.ReactNode => {
  const tooltipTitle = disabled ? disabledTooltipTitle : ''

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name={name}
              onChange={handleCheck}
              checked={checked}
              disabled={disabled}
            />
          }
          label={children}
        />
      </Tooltip>
    </>
  )
}
