import moment from 'moment-timezone'
import { constants } from '../constants'
import { eventIsEditable } from './eventIsEditable'
import { mapKeywordSetToForm } from './apiDataMapping'
import {
  APIEvent,
  EventTime,
  ExternalLink,
  HobbyCategory,
  Keyword,
  MultiLanguageField,
  UIEvent,
  WrappedId,
} from '../types/apiTypes'
import { isHobby, isLibrary } from '../validation/validationRules'

const { PUBLICATION_STATUS } = constants

export type TopicType =
  | 'harrastushaku'
  | 'kirjastotapahtumat'
  | 'kulttuuritapahtumat'
  | 'muuttapahtumat'
export type YsoMapping = { [key in TopicType]: string | undefined }

export const ysoShortCodeMapping: YsoMapping = {
  harrastushaku: 'yso:p2901',
  kirjastotapahtumat: 'yso:p2787',
  kulttuuritapahtumat: 'yso:p360',
  muuttapahtumat: undefined,
}

function nullifyEmptyStrings(
  multiLangObject: MultiLanguageField
): MultiLanguageField

function nullifyEmptyStrings(val: string | undefined): string

function nullifyEmptyStrings(
  val: MultiLanguageField | string | undefined
): MultiLanguageField | string | null {
  if (!val) return null
  if (typeof val === 'string') return val

  const multiLangObject = val
  const nullifiedMultiLangObject = Object.entries(multiLangObject).reduce(
    (acc, [key, value]) => {
      return { ...acc, [key]: value === '' ? null : value }
    },
    multiLangObject
  )

  return nullifiedMultiLangObject
}

function toWrappedId(val: string | { '@id': string }): WrappedId {
  if (typeof val === 'string') {
    return { '@id': val }
  }
  return { '@id': val['@id'] }
}

function getYsoFromId(id: string): string {
  const arr = id.split('/')
  return arr[arr.length - 2]
}

const overlappingTopics = ['yso:p1808', 'yso:p6062', 'yso:p5121', 'yso:p8113']

function toKeywords(
  values: UIEvent,
  libraryKeywordIds: string[]
): Array<WrappedId> {
  // Topic and place keywords, audience, languages
  const keywords =
    values.keywords && values.keywords.length
      ? values.keywords
          .filter((k) => {
            return (
              (overlappingTopics.includes(getYsoFromId(k)) ||
                !libraryKeywordIds?.includes(k)) &&
              !Object.values(ysoShortCodeMapping).includes(getYsoFromId(k))
            )
          })
          .map(toWrappedId)
      : []

  const libraryKeywords =
    isLibrary(values) && values.libraryKeywords && values.libraryKeywords.length
      ? values.libraryKeywords.map(toWrappedId)
      : []
  // The topic and place keywords are stored in separate fields in the UI form but they are in the same "keywords" field
  // in the API. Thus, we need to map both the UI "keywords" and "placeKeywords" fields to the API "keywords" field.
  const placeKeywords =
    values.placeKeywords && values.placeKeywords.length
      ? values.placeKeywords.map(toWrappedId)
      : []

  const metaKeywords =
    values.metaKeywords && values.metaKeywords.length
      ? values.metaKeywords
          .filter((value) => !(value in ysoShortCodeMapping))
          .map(toWrappedId)
      : []

  return Array.from(
    new Set([
      ...keywords,
      ...libraryKeywords,
      ...placeKeywords,
      ...metaKeywords,
    ])
  )
}

const createExternalLink = (
  values: UIEvent | undefined,
  name: 'extlink_facebook' | 'extlink_twitter' | 'extlink_instagram'
): ExternalLink | undefined => {
  const link = values?.[name]
  if (!link) {
    return undefined
  }

  return {
    name,
    link,
    language: 'fi', // TODO: Which languages here?
  }
}

// TODO: Refactoring form components to output and accept the correct format (like <MultiLanguageField>
// to output {fi: name, se: namn})

function mapUIDataToAPIFormat(
  values: UIEvent,
  keywordSets: Keyword[][]
): APIEvent | {} {
  if (!values) {
    return {}
  }

  const libraryKeywordIds = mapKeywordSetToForm(
    keywordSets,
    'espoo:librarytopics'
  ).map((keywordObj) => keywordObj.value)

  const obj: APIEvent = {
    id: values.id,
    name: nullifyEmptyStrings(values.name),
    short_description: nullifyEmptyStrings(values.short_description),
    description: nullifyEmptyStrings(values.description_html),
    description_html: nullifyEmptyStrings(values.description_html),
    info_url: nullifyEmptyStrings(values.info_url),
    provider: nullifyEmptyStrings(values.provider),
    publication_status: values.publication_status || PUBLICATION_STATUS.DRAFT,
    super_event_type: values.super_event_type,
    super_event: values.super_event ? values.super_event : null,
    publisher: values.organization,
    location: values.location ? toWrappedId(values.location) : null,
    location_extra_info: values.location_extra_info
      ? nullifyEmptyStrings(values.location_extra_info)
      : null,
    images: values.image ? [values.image] : [],
    offers:
      values.offers && values.offers.length && !values.offers[0].is_free
        ? values.offers
        : [{ is_free: true }],
    keywords: toKeywords(values, libraryKeywordIds),
    audience:
      values.audience && values.audience.length !== undefined
        ? values.audience.map(toWrappedId)
        : [],
    in_language: values.in_language?.map(toWrappedId),
    external_links: [
      createExternalLink(values, 'extlink_facebook'),
      createExternalLink(values, 'extlink_twitter'),
      createExternalLink(values, 'extlink_instagram'),
    ].filter((link): link is ExternalLink => !!link),
    event_registration_link: nullifyEmptyStrings(
      values.event_registration_link
    ),
    date_published: !values.publication_status
      ? moment().utc().format()
      : values.date_published || null,
    start_time: values.start_time ? values.start_time : null,
    end_time: values.end_time ? values.end_time : null,
    audience_min_age: values.audience_min_age
      ? parseInt(values.audience_min_age, 10)
      : null,
    audience_max_age: values.audience_max_age
      ? parseInt(values.audience_max_age, 10)
      : null,
    hobby_categories: isHobby(values)
      ? values.hobbyCategories?.map(toWrappedId)
      : [],
    kaikukortti: values?.kaikukortti ?? false,
    museokortti: values?.museokortti ?? false,
    /**
     * @deprecated TODO: Courses extension is removed from frontend.
     * Remove this, once courses is removed from backend (service).
     */
    extension_course: {
      enrolment_start_time: values.enrolment_start_time
        ? values.enrolment_start_time
        : null,
      enrolment_end_time: values.enrolment_end_time
        ? values.enrolment_end_time
        : null,
      minimum_attendee_capacity: values.minimum_attendee_capacity
        ? parseInt(values.minimum_attendee_capacity, 10)
        : null,
      maximum_attendee_capacity: values.maximum_attendee_capacity
        ? parseInt(values.maximum_attendee_capacity, 10)
        : null,
    },
  }

  return obj
}

function mapAPIDataToUIFormat(
  values: APIEvent,
  keywordSets: Keyword[][]
): UIEvent | {} {
  if (!values) {
    return {}
  }

  const libraryKeywordIds = mapKeywordSetToForm(
    keywordSets,
    'espoo:librarytopics'
  ).map((keywordObj) => keywordObj.value)

  const placeKeywordIds = mapKeywordSetToForm(keywordSets, 'espoo:places').map(
    (keywordObj) => keywordObj.value
  )

  const metaIds = ['yso:p2901', 'yso:p2787', 'yso:p360']

  const metaKeywords = values.keywords
    ?.filter((key) => metaIds.includes((key as Keyword).id))
    .map((key) => key['@id'])

  const otherTopics = values.keywords
    ?.map((item) => item['@id'])
    .filter(
      (topicKeywordId) =>
        !placeKeywordIds.includes(topicKeywordId) &&
        !libraryKeywordIds.includes(topicKeywordId) &&
        !metaKeywords.includes(topicKeywordId)
    )

  const hasOtherTopics = otherTopics?.length > 0

  const obj: UIEvent = {
    id: values.id,
    name: values.name,
    short_description: values.short_description,
    description: values.description,
    description_html: values.description_html
      ? values.description_html
      : values.description,
    info_url: values.info_url,
    provider: values.provider,
    metaKeywords:
      metaKeywords &&
      !metaKeywords.includes(
        ysoShortCodeMapping['kulttuuritapahtumat'] as string
      ) &&
      hasOtherTopics
        ? [...metaKeywords, 'muuttapahtumat']
        : metaKeywords,
    super_event_type: values.super_event_type,
    event_registration_link: values.event_registration_link
      ? values.event_registration_link
      : undefined,
    event_status: values.event_status,
    publication_status: values.publication_status,
    organization: values.publisher,
    location: values.location ? values.location : undefined,
    location_extra_info: values.location_extra_info
      ? values.location_extra_info
      : undefined,
    offers: values.offers,
    sub_events: { ...values.sub_events },
    keywords: values.keywords
      ? values.keywords
          .map((item) => item['@id'])
          .filter((topicKeywordId) => !placeKeywordIds.includes(topicKeywordId))
      : [],
    libraryKeywords:
      values.keywords &&
      metaKeywords
        .map(getYsoFromId)
        .includes(ysoShortCodeMapping['kirjastotapahtumat'] as string)
        ? values.keywords
            .map((item) => item['@id'])
            .filter((libraryKeywordId) =>
              libraryKeywordIds.includes(libraryKeywordId)
            )
        : [],
    placeKeywords: values.keywords
      ? values.keywords
          .map((item) => item['@id'])
          .filter((placeKeywordId) => placeKeywordIds.includes(placeKeywordId))
      : [],
    audience: values.audience?.map((item) => item['@id']),
    in_language: values.in_language?.map((lang) => lang['@id']),
    extlink_facebook: values.external_links?.find(
      (item) => item.name === 'extlink_facebook'
    )?.link,
    extlink_twitter: values.external_links?.find(
      (item) => item.name === 'extlink_twitter'
    )?.link,
    extlink_instagram: values.external_links?.find(
      (item) => item.name === 'extlink_instagram'
    )?.link,
    date_published: values.date_published ? values.date_published : undefined,
    start_time: values.start_time ? values.start_time : undefined,
    end_time: values.end_time ? values.end_time : undefined,
    image: values.images?.length > 0 ? values.images[0] : undefined,
    audience_min_age: values.audience_min_age?.toString(),
    audience_max_age: values.audience_max_age?.toString(),
    hobbyCategories: values.hobby_categories as HobbyCategory[],
    kaikukortti: values?.kaikukortti ?? false,
    museokortti: values?.museokortti ?? false,
  }
  return obj
}

export { mapUIDataToAPIFormat, mapAPIDataToUIFormat }

/*
    take an array of sub events, return start and end time for the
    corresponding super event with:
    - earliest date of sub events as start_time
    - latest date of sub events as end_time
*/
// TODO: calculateSuperEventTime actually receives objects => change the parameter type
export const calculateSuperEventTime = (
  subEvents: Array<UIEvent>
): EventTime => {
  const startTimes = Object.values(subEvents).flatMap((e) =>
    e.start_time ? moment(e.start_time) : []
  )
  const endTimes = Object.values(subEvents).flatMap((e) =>
    e.end_time ? moment(e.end_time) : moment(e.start_time).endOf('day')
  )

  const superEventStartTime =
    startTimes.length > 0 ? moment.min(startTimes) : undefined

  // set value to new object, because moment.js mutates the object
  const newStartTime = moment
    .tz(superEventStartTime, 'Europe/Helsinki')
    .utc()
    .toISOString()

  // in case there is no end_time in sub events should return the
  // midnight of the day after the latest start time as super event endtime
  const latestEndTime = endTimes.length > 0 ? moment.max(endTimes) : undefined
  const latestStartTime =
    startTimes.length > 0 ? moment.max(startTimes) : undefined
  const superEventEndTime = latestEndTime || latestStartTime?.endOf('day')

  return {
    start_time: superEventStartTime ? newStartTime : undefined,
    end_time: superEventEndTime
      ? moment.tz(superEventEndTime, 'Europe/Helsinki').utc().toISOString()
      : undefined,
  }
}

type UIEventWithSubEventTimes = Omit<UIEvent, 'sub_events'> & {
  sub_events: { [x: number]: EventTime }
}

export const splitSubEvents = (subEvents: {
  [x: number]: EventTime & { '@id'?: string }
}): {
  oldSubEvents: { [x: number]: EventTime }
  newSubEvents: { [x: number]: EventTime }
} => ({
  oldSubEvents: Object.assign(
    {},
    Object.values(subEvents).filter((event) => !!event['@id'])
  ),
  newSubEvents: Object.assign(
    {},
    Object.values(subEvents).filter((event) => !event['@id'])
  ),
})

export const createSubEventsFromFormValues = (
  formValues: UIEventWithSubEventTimes,
  superEventUrl: string
): UIEventWithSubEventTimes[] =>
  Object.entries(formValues.sub_events).map(([_, subEvent]) => ({
    ...formValues,
    id: undefined,
    sub_events: {}, // empty sub events, otherwise they would refer to themselves.
    super_event: { '@id': superEventUrl },
    super_event_type: null,
    start_time: subEvent.start_time,
    end_time: subEvent.end_time,
  }))

// update form data with sub event data where applicable
// TODO this updates sub events in a very unintuitive way and should be refactored
export const updateSubEventsFromFormValues = (
  formValues: UIEvent,
  subEventsToUpdate: { [x: number]: UIEvent }
): UIEvent[] =>
  Object.values(subEventsToUpdate)
    // TODO don't update canceled, deleted or past subevents (when editing an ongoing series =)
    .filter((subEvent) => eventIsEditable(subEvent).editable)
    .map((subEvent) => ({
      ...formValues,
      start_time: subEvent.start_time,
      end_time: subEvent.end_time,
      id: subEvent.id,
      super_event: subEvent.super_event,
      super_event_type: subEvent.super_event_type,
    }))
