import { every, forOwn, includes, isNull, keys } from 'lodash'
import moment from 'moment'
import validator from 'validator'
import { constants } from '../constants'
import { textLimitValidator } from '../utils/helpers'
import { mapKeywordSetToForm } from '../utils/apiDataMapping'
/**
 * Notice that all the validation functions follow the Formsy's parameter setup (values, value)
 * where values are all the form valus and value the tested field value
 */

const isExisty = (value) => value !== null && value !== undefined

const isEmpty = (value) => value === ''

const containsAllLanguages = (value, languages) => {
  const requiredLanguages = new Set(languages)
  forOwn(value, (item, key) => {
    if (isNull(item) || (item.length && item.length > 0)) {
      requiredLanguages.delete(key)
    }
  })
  return requiredLanguages.size === 0
}

// yso:p2901 is the yso id for hobbies
export const isHobby = (values) =>
  values &&
  values.metaKeywords?.some((keyword) => keyword.includes('yso:p2901'))

// yso:p2787 is the yso id for hobbies
export const isLibrary = (values) =>
  values &&
  values.metaKeywords?.some((keyword) => keyword.includes('yso:p2787'))

const isUrl = (value) =>
  validator.isURL(value, {
    require_protocol: true,
    protocols: ['http', 'https'],
  })

export const validationRules = {
  isDefaultRequiredValue: function isDefaultRequiredValue(values, value) {
    return value === undefined || value === ''
  },
  isExisty: (values, value) => isExisty(value),
  matchRegexp: function matchRegexp(values, value, regexp) {
    return !isExisty(value) || isEmpty(value) || regexp.test(value)
  },
  isUndefined: function isUndefined(values, value) {
    return value === undefined
  },
  isEmptyString: function isEmptyString(values, value) {
    return isEmpty(value)
  },
  isEmail: function isEmail(values, value) {
    return value ? validator.isEmail(value) : true
  },
  isUrl: (values, value, key) => {
    if (!value || !values) {
      return true
    }

    if (key === 'undefined') {
      return every(value, (item) => isUrl(item))
    }

    if (key === 'info_url') {
      // Check all language urls
      let finnishUrlValidationPassed = true
      let englishUrlValidationPassed = true
      let swedishUrlValidationPassed = true

      const info_url = value.info_url ?? value

      if (info_url) {
        if (info_url.fi) {
          finnishUrlValidationPassed = isUrl(info_url.fi)
        }
        if (info_url.en) {
          englishUrlValidationPassed = isUrl(info_url.en)
        }
        if (info_url.sv) {
          swedishUrlValidationPassed = isUrl(info_url.sv)
        }
      }

      return (
        finnishUrlValidationPassed &&
        englishUrlValidationPassed &&
        swedishUrlValidationPassed
      )
    }

    return isUrl(value)
  },
  isTrue: function isTrue(values, value) {
    return value === true
  },
  isFalse: function isFalse(values, value) {
    return value === false
  },
  isNumeric: function isNumeric(values, value) {
    if (typeof value === 'number') {
      return true
    }
    return validationRules.matchRegexp(values, value, /^[-+]?(?:\d*[.])?\d+$/)
  },
  isAlpha: function isAlpha(values, value) {
    return validationRules.matchRegexp(values, value, /^[A-Z]+$/i)
  },
  isAlphanumeric: function isAlphanumeric(values, value) {
    return validationRules.matchRegexp(values, value, /^[0-9A-Z]+$/i)
  },
  isInt: function isInt(values, value) {
    return validationRules.matchRegexp(
      values,
      value,
      /^(?:[-+]?(?:0|[1-9]\d*))$/
    )
  },
  isFloat: function isFloat(values, value) {
    return validationRules.matchRegexp(
      values,
      value,
      /^(?:[-+]?(?:\d+))?(?:\.\d*)?(?:[eE][+-]?(?:\d+))?$/
    )
  },
  isWords: function isWords(values, value) {
    return validationRules.matchRegexp(values, value, /^[A-Z\s]+$/i)
  },
  isSpecialWords: function isSpecialWords(values, value) {
    return validationRules.matchRegexp(
      values,
      value,
      /^[A-Z\s\u00C0-\u017F]+$/i
    )
  },
  isLength: function isLength(values, value, length) {
    return !isExisty(value) || isEmpty(value) || value.length === length
  },
  equals: function equals(values, value, eql) {
    return !isExisty(value) || isEmpty(value) || value === eql
  },
  equalsField: function equalsField(values, value, field) {
    return value === values[field]
  },
  maxLength: function maxLength(values, value, length) {
    return !isExisty(value) || value.length <= length
  },
  minLength: function minLength(values, value, length) {
    return !isExisty(value) || isEmpty(value) || value.length >= length
  },
  isTime: function isTime(values, value) {
    // Empty string needs not match, because HelTimePicker does not run validations on empty strings anyway.
    // However, HelDateTimeField itself runs this too, and there we must *not* accept empty time.
    return validationRules.matchRegexp(
      values,
      value,
      /(24((:|\.)00)?)|^((2[0-3]|1[0-9]|0[0-9]|[0-9])((:|\.)[0-5][0-9])?)$/i
    )
  },
  isDate(values, value) {
    return value ? moment(value, moment.ISO_8601, true).isValid() : true
  },
  beforeStartTime: function beforeStartTime(values, value) {
    if (!values.start_time || !value) {
      return true
    }

    const time = new Date(value)
    const startTime = new Date(values.start_time)

    return time - startTime < 0
  },
  afterStartTime: function afterStartTime(values, value) {
    if (!values.start_time || !value) {
      return true
    }

    const time = new Date(value)
    const startTime = new Date(values.start_time)

    return time - startTime >= 0
  },
  afterEnrolmentStartTime: function afterEnrolmentStartTime(values, value) {
    if (!values.enrolment_start_time || !value) {
      return true
    }
    return new Date(value) >= new Date(values.enrolment_start_time)
  },
  inTheFuture: function inTheFuture(values, value) {
    if (!value) {
      return true
    }

    const now = new Date()
    const time = new Date(value)

    if (time - now >= 0) {
      return true
    }

    return false
  },
  // This validates that the default end time set to an event without end time is valid.
  // espooevents-service accepts events that have no end time. However, if there's no end time, espooevents-service will
  // by default add the end time to the midnight based on the start time. In this case, if the start time is earlier
  // than today, the end time will also be in the past and espooevents-service doesn't accept events that have their
  // end time in the past. That's why we must not send an event that has a start time earlier than today and has no end
  // time. However, if an end time is given, the start time can be earlier than today.
  defaultEndInTheFuture: (values, startTime) => {
    if (values.end_time) {
      return true
    }
    const defaultEndTime = moment(startTime).endOf('day')
    return defaultEndTime.diff(moment()) > 0
  },

  required: function required(values, value) {
    return isExisty(value)
  },

  requiredMulti(values, value) {
    if (typeof value !== 'object' || !value) {
      return false
    }
    if (keys(value).length === 0) {
      return false
    }
    return every(
      value,
      (item) => isNull(item) || (item.trim() && item.trim().length > 0)
    )
  },
  requiredAtId: function requiredAtId(values, value) {
    if (typeof value !== 'object' || !value) {
      return false
    }
    if (typeof value['@id'] !== 'string') {
      return false
    }
    if (value['@id'].length === 0) {
      return false
    }

    return true
  },
  atLeastOne: function atLeastOne(values, value) {
    if (value && value.length && value.length > 0) {
      return true
    }
    return false
  },
  atLeastOneTopic(values, value, keywordSets) {
    if (!value) {
      return false
    }
    return mapKeywordSetToForm(keywordSets, 'espoo:topics')
      .map((keywordObj) => keywordObj.value)
      .some((keywordId) => value.find((item) => item.includes(keywordId)))
  },
  atLeastOnePlaceKeyword(values, value, keywordSets) {
    if (!value) {
      return false
    }
    return mapKeywordSetToForm(keywordSets, 'espoo:places')
      .map((keywordObj) => keywordObj.value)
      .some((keywordId) => value.find((item) => item.includes(keywordId)))
  },
  shortString: function shortString(values, value) {
    return textLimitValidator(value, constants.CHARACTER_LIMIT.SHORT_STRING)
  },
  mediumString: function mediumString(values, value) {
    return textLimitValidator(value, constants.CHARACTER_LIMIT.MEDIUM_STRING)
  },
  longString: function longString(values, value) {
    return textLimitValidator(value, constants.CHARACTER_LIMIT.LONG_STRING)
  },
  requiredInContentLanguages: function requiredInContentLanguages(
    values,
    value
  ) {
    if (typeof value !== 'object') {
      return false
    }
    return containsAllLanguages(value, values.contentLanguages)
  },
  offerIsFreeOrHasPrice: function offerIsFreeOrHasPrice(values, value, key) {
    if (typeof value !== 'object') {
      return false
    }
    return containsAllLanguages(value[key], values.contentLanguages)
  },
  atLeastOneIsTrue: function atLeastOneIsTrue(values, value) {
    for (const key in value) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(key)) {
        if (value[key]) {
          return true
        }
      }
    }

    return false
  },
  isMoreThanOne: function isMoreThanOne(values, value) {
    return value > 0
  },
  daysWithinInterval: function daysWithinInterval(values, value) {
    if (!(value < 6)) {
      return true
    }
    const {
      start_day_index: startDayIndex,
      end_day_index: endDayIndex,
      daysSelected,
    } = values
    const dayCodes = {
      monday: 0,
      tuesday: 1,
      wednesday: 2,
      thursday: 3,
      friday: 4,
      saturday: 5,
      sunday: 6,
    }
    const daysSelectedState = []
    let betweenInterval

    if (startDayIndex <= endDayIndex) {
      betweenInterval = true
    } else {
      betweenInterval = false
    }

    for (const key in daysSelected) {
      if (daysSelected[key] === true) {
        if (betweenInterval) {
          daysSelectedState.push(
            startDayIndex <= dayCodes[key] && dayCodes[key] <= endDayIndex
          )
        } else {
          daysSelectedState.push(
            dayCodes[key] <= endDayIndex || startDayIndex <= dayCodes[key]
          )
        }
      }
    }

    if (includes(daysSelectedState, false)) {
      return false
    }

    return true
  },
  hasPrice: function hasPrice(values, value, key) {
    if (value.is_free !== undefined && !value.is_free) {
      const validateLanguages = (x) => {
        let hasFinnish = true
        let hasEnglish = true
        let hasSwedish = true
        let hasLanguage = false
        if (x.fi) {
          hasLanguage = true
          hasFinnish = !!x.fi.length
        }
        if (x.en) {
          hasLanguage = true
          hasEnglish = !!x.en.length
        }
        if (x.sv) {
          hasLanguage = true
          hasSwedish = !!x.sv.length
        }
        return hasLanguage && hasFinnish && hasEnglish && hasSwedish
      }
      return value[key] && validateLanguages(value[key])
    }
    return true
  },
  isHobbyWithNoAgeLimits(values, value) {
    if (isHobby(values) && !value) {
      return false
    }
    return true
  },
  isServerError(values, value, key) {
    const { serverErrorData } = values
    if (serverErrorData == null) return true
    if (
      (key === 'start_time' &&
        serverErrorData.start_time != null &&
        moment(serverErrorData.start_time).isAfter(moment(value))) ||
      (key === 'end_time' &&
        serverErrorData.end_time != null &&
        moment(serverErrorData.end_time).isBefore(moment(value))) ||
      (key === 'date_published' &&
        serverErrorData.date_published != null &&
        moment(serverErrorData.date_published).isBefore(moment(value)))
    ) {
      return false
    }
    return true
  },
}
