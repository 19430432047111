import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { OrganizationSelect } from './UserInfoPage'
import { useClient } from '../../api/clientContext'
import isEmail from 'validator/lib/isEmail'
import { useDispatch } from 'react-redux'
import { setFlashMsg } from '../../store/app/index'
import { OrganizationAccessRequest } from '../../types/apiTypes'

const OrganizationAccessRequestForm: React.FC<{
  userOrganizations: string[]
  organizations: OrganizationSelect[]
  organizationAccessRequests: OrganizationAccessRequest[]
  setRefreshing: (b: boolean) => void
}> = ({
  userOrganizations,
  organizations,
  organizationAccessRequests,
  setRefreshing,
}) => {
  const client = useClient()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationSelect | null>(null)
  const [orgsToBeShown, setOrgsToBeShown] = useState<OrganizationSelect[]>([])
  const [accessRequestEmail, setAccessRequestEmail] = useState<string>('')

  // TODO: Should you use redux editor/setLoading instead?
  const [loading, setLoading] = useState(false)

  const formReady = (): boolean =>
    !!selectedOrganization && isEmail(accessRequestEmail)

  useEffect(
    () =>
      setOrgsToBeShown(
        organizations.filter(
          (org) =>
            !userOrganizations.includes(org.id) &&
            !organizationAccessRequests.some(
              (request) => request.organization === org.id
            )
        )
      ),
    [organizations, userOrganizations, organizationAccessRequests]
  )

  const handleSubmitAccessRequest = async (): Promise<void> => {
    if (!client) {
      console.warn('Client not initialized')
      return
    }

    setLoading(true)

    try {
      await client.post('organizationaccessrequest', {
        organization: selectedOrganization?.id,
        email: accessRequestEmail,
      })
      dispatch(
        setFlashMsg({
          msg: 'organization-access-request-sent',
          style: 'message',
          sticky: false,
        })
      )

      setOrgsToBeShown(
        orgsToBeShown.filter((org) => org.id !== selectedOrganization?.id)
      )
      setAccessRequestEmail('')
      setSelectedOrganization(null)
    } catch (error) {
      dispatch(
        setFlashMsg({
          msg: 'generic-error-message',
          style: 'error',
          sticky: false,
        })
      )
    }

    setRefreshing(true)
    setLoading(false)
  }

  return (
    <div className="organization-access-request-container">
      <div className="organization-picker">
        <p>
          <strong>{intl.formatMessage({ id: 'choose-organization' })}</strong>
        </p>
        <Select
          placeholder={intl.formatMessage({ id: 'choose-organization' })}
          options={orgsToBeShown}
          onChange={(selectedOption) =>
            setSelectedOrganization(selectedOption as OrganizationSelect)
          }
          isOptionSelected={(option) => option.id === selectedOrganization?.id}
          value={selectedOrganization}
        />
      </div>
      <div className="access-request-email">
        <p style={{ paddingTop: '16px' }}>
          <strong>
            {intl.formatMessage({ id: 'organization-access-request-email' })}
          </strong>
        </p>
        <TextField
          style={{ marginTop: '0px', paddingBottom: '32px' }}
          value={accessRequestEmail}
          onChange={(e) => setAccessRequestEmail(e.target.value)}
          onKeyPress={(e) =>
            e.key === 'Enter' && formReady() && handleSubmitAccessRequest()
          }
        />
        <Button
          disabled={loading || !formReady()}
          variant="contained"
          onClick={handleSubmitAccessRequest}
          endIcon={loading && <CircularProgress color="inherit" size={25} />}
          color="primary"
        >
          {intl.formatMessage({ id: 'send-organization-access-request' })}
        </Button>
      </div>
    </div>
  )
}

export default OrganizationAccessRequestForm
